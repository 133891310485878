var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.allowEdit ? _c('div', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "mr-5",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "text-h5 pb-5 font-weight-bold"
  }, [_vm._v("Edit Job")])], 1), _c('v-form', {
    ref: "basicDetails",
    staticClass: "basic pa-5",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.basicStep,
      callback: function ($$v) {
        _vm.basicStep = $$v;
      },
      expression: "basicStep"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "role"
    }
  }, [_vm._v(" Job Role ")]), _c('v-text-field', {
    attrs: {
      "id": "role",
      "outlined": "",
      "placeholder": "Title of Role",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.jobRole,
      callback: function ($$v) {
        _vm.jobRole = $$v;
      },
      expression: "jobRole"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "location"
    }
  }, [_vm._v(" Location ")]), _c('v-combobox', {
    attrs: {
      "items": [],
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "outlined": "",
      "placeholder": "Enter location",
      "rules": [function (v) {
        return v.length > 0 || 'Required';
      }]
    },
    model: {
      value: _vm.location,
      callback: function ($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "jobType"
    }
  }, [_vm._v(" Job Type ")]), _c('v-select', {
    attrs: {
      "items": _vm.jobTypes,
      "item-value": "value",
      "item-text": "name",
      "placeholder": "Select Job Type",
      "rules": _vm.jobTypeRules,
      "outlined": ""
    },
    model: {
      value: _vm.jobType,
      callback: function ($$v) {
        _vm.jobType = $$v;
      },
      expression: "jobType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "experience"
    }
  }, [_vm._v(" Experience ")]), _c('v-text-field', {
    attrs: {
      "placeholder": "Eg., 1-3",
      "rules": [function (v) {
        return !!v || 'Required';
      }, function (v) {
        return _vm.expFormatValid || 'Invalid Format';
      }],
      "outlined": ""
    },
    on: {
      "input": _vm.parseExperience
    },
    model: {
      value: _vm.rawExpTxt,
      callback: function ($$v) {
        _vm.rawExpTxt = $$v;
      },
      expression: "rawExpTxt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "preference"
    }
  }, [_vm._v(" Job Preference ")]), _c('v-combobox', {
    attrs: {
      "items": _vm.jobPrefTypes,
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "item-value": "value",
      "item-text": "name",
      "placeholder": "Select Job Preference",
      "rules": [function (v) {
        return !!v || 'Required';
      }],
      "outlined": ""
    },
    model: {
      value: _vm.preference,
      callback: function ($$v) {
        _vm.preference = $$v;
      },
      expression: "preference"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('label', {
    staticClass: "font-weight-bold text-h6",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(" Description ")]), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "placeholder": "Job Description",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.description,
      callback: function ($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('div', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v("Selected Skills")])]), _c('v-row', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.selectedSkills, function (sel, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.removeSelectedSkill(idx);
        }
      }
    }, [_vm._v(" " + _vm._s(sel) + " ")]);
  }), 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addCustomSkill.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newSkill,
      callback: function ($$v) {
        _vm.newSkill = $$v;
      },
      expression: "newSkill"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.addCustomSkill
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _c('span', [_vm._v(" Add Skill ")])], 1)], 1)], 1), _c('v-row', [_c('div', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v("Quick Pick Skills")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.selectedSkills,
      callback: function ($$v) {
        _vm.selectedSkills = $$v;
      },
      expression: "selectedSkills"
    }
  }, _vm._l(_vm.skills, function (skill) {
    return _c('v-chip', {
      key: skill,
      attrs: {
        "value": skill,
        "active-class": "success white--text"
      }
    }, [_vm._v(" " + _vm._s(skill) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pb-0 mb-0"
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "currency"
    }
  }, [_vm._v(" Salary Range "), _c('span', {
    staticClass: "text-body-2 grey--text"
  }, [_vm._v("per month")])])])], 1), _c('v-form', {
    ref: "salaryBenefitsForm",
    staticClass: "pa-5",
    model: {
      value: _vm.salaryBenefitsForm,
      callback: function ($$v) {
        _vm.salaryBenefitsForm = $$v;
      },
      expression: "salaryBenefitsForm"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": ['INR', 'USD', 'GBP', 'EURO'],
      "placeholder": "Currency",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.currency,
      callback: function ($$v) {
        _vm.currency = $$v;
      },
      expression: "currency"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Min. Salary",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.minSalary,
      callback: function ($$v) {
        _vm.minSalary = $$v;
      },
      expression: "minSalary"
    }
  }), _c('span', {
    staticClass: "mt-2 px-2"
  }, [_vm._v(" to ")]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Max. Salary",
      "dense": "",
      "rules": [function (v) {
        return !!v || 'Required';
      }]
    },
    model: {
      value: _vm.maxSalary,
      callback: function ($$v) {
        _vm.maxSalary = $$v;
      },
      expression: "maxSalary"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.paymentFrequencies,
      "item-value": "value",
      "item-text": "name",
      "dense": "",
      "placeholder": "Select Payment Frequency",
      "rules": _vm.paymentFrequencyRules,
      "outlined": ""
    },
    model: {
      value: _vm.paymentFrequency,
      callback: function ($$v) {
        _vm.paymentFrequency = $$v;
      },
      expression: "paymentFrequency"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-row"
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "Benefits"
    }
  }, [_vm._v(" Benefits ")]), _c('v-btn', {
    attrs: {
      "color": "grey",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.benefits = _vm.defaultBenefits;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiRestore) + " ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "d-flex flex-row flex-wrap"
  }, _vm._l(_vm.benefits, function (benefit, idx) {
    return _c('v-checkbox', {
      key: idx,
      staticClass: "px-3",
      attrs: {
        "label": benefit,
        "value": benefit,
        "hide-details": ""
      },
      model: {
        value: _vm.benefits,
        callback: function ($$v) {
          _vm.benefits = $$v;
        },
        expression: "benefits"
      }
    });
  }), 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addBenefit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newBenefit,
      callback: function ($$v) {
        _vm.newBenefit = $$v;
      },
      expression: "newBenefit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.addBenefit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]), _c('span', [_vm._v(" Add Benefit ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "currency"
    }
  }, [_vm._v(" Equity ")]), _c('div', {
    staticClass: "d-flex flex-row pt-3"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Min. Equity %",
      "dense": "",
      "rules": _vm.equityRules
    },
    model: {
      value: _vm.minEquityPercent,
      callback: function ($$v) {
        _vm.minEquityPercent = $$v;
      },
      expression: "minEquityPercent"
    }
  }), _c('span', {
    staticClass: "mt-2 px-2"
  }, [_vm._v(" to ")]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "Max. Equity %",
      "dense": "",
      "rules": _vm.equityRules
    },
    model: {
      value: _vm.maxEquityPercent,
      callback: function ($$v) {
        _vm.maxEquityPercent = $$v;
      },
      expression: "maxEquityPercent"
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "expiry-picker"
    }
  }, [_vm._v(" Expiry Date ")]), _c('div', [_c('v-date-picker', {
    attrs: {
      "id": "expiry-picker",
      "allowed-dates": _vm.allowFutureDates,
      "elevation": "1"
    },
    model: {
      value: _vm.expiryEpoch,
      callback: function ($$v) {
        _vm.expiryEpoch = $$v;
      },
      expression: "expiryEpoch"
    }
  })], 1)])], 1)], 1), _c('div', {
    staticClass: "save-btn"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "aria-label": "Save Button",
      "fab": "",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiFloppy) + " ")])], 1)], 1)], 1) : _c('div', {
    staticClass: "text-center text-h6"
  }, [_vm._v("Loading ...")]);

}
var staticRenderFns = []

export { render, staticRenderFns }