var render = function render(){
  var _vm$jobView$getTitle, _vm$jobView, _vm$jobView2, _vm$jobMetaData, _vm$jobMetaData2, _vm$jobInfo, _vm$jobMetaData3, _vm$jobMetaData3$getL, _vm$jobMetaData4, _vm$jobMetaData5, _vm$jobMetaData6, _vm$jobCtc, _vm$jobCtc2, _vm$jobCtc3, _vm$paymentFrequencie, _vm$jobCtc4, _vm$jobCtc4$getPaymen, _vm$jobCtc5, _vm$jobCtc6, _vm$jobInfo2, _vm$jobInfo3, _vm$preferenceList, _vm$jobView3, _this$linkedTest;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "lg",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-bold justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "px-n3",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v(" " + _vm._s((_vm$jobView$getTitle = (_vm$jobView = _vm.jobView) === null || _vm$jobView === void 0 ? void 0 : _vm$jobView.getTitle()) !== null && _vm$jobView$getTitle !== void 0 ? _vm$jobView$getTitle : 'Loading ...') + " ")])], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "elevation": "0",
      "to": `${_vm.$route.fullPath}/edit`
    }
  }, [_vm._v(" Edit Job ")])], 1), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.panels,
      callback: function ($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_c('v-expansion-panel', {
    staticClass: "mt-5"
  }, [_c('v-expansion-panel-header', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v(" Information ")]), _c('v-expansion-panel-content', [_c('v-card', {
    staticClass: "pa-5 text-body-1",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Posted On ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$jobView2 = _vm.jobView) === null || _vm$jobView2 === void 0 ? void 0 : _vm$jobView2.getCreatedOn()) || '-') + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Expiry On ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(new Date((_vm$jobMetaData = _vm.jobMetaData) === null || _vm$jobMetaData === void 0 ? void 0 : _vm$jobMetaData.getExpiryDate()).toDateString() || '-') + " ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Applicants ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData2 = _vm.jobMetaData) === null || _vm$jobMetaData2 === void 0 ? void 0 : _vm$jobMetaData2.getCandidatesApplied()) + " ")])], 1)], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "loading": _vm.jobLoading,
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v("Details")]), _c('v-card-text', {
    staticClass: "text-body-1 black--text"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Job Role ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(_vm._s((_vm$jobInfo = _vm.jobInfo) === null || _vm$jobInfo === void 0 ? void 0 : _vm$jobInfo.getRole()))])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Location ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData3 = _vm.jobMetaData) === null || _vm$jobMetaData3 === void 0 ? void 0 : (_vm$jobMetaData3$getL = _vm$jobMetaData3.getLocationList()) === null || _vm$jobMetaData3$getL === void 0 ? void 0 : _vm$jobMetaData3$getL.join(', ')) + " ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Job Type ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.jobType(_vm.jobTypeKeys[(_vm$jobMetaData4 = _vm.jobMetaData) === null || _vm$jobMetaData4 === void 0 ? void 0 : _vm$jobMetaData4.getJobType()])) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Experience ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s((_vm$jobMetaData5 = _vm.jobMetaData) === null || _vm$jobMetaData5 === void 0 ? void 0 : _vm$jobMetaData5.getMinExperience()) + "-" + _vm._s((_vm$jobMetaData6 = _vm.jobMetaData) === null || _vm$jobMetaData6 === void 0 ? void 0 : _vm$jobMetaData6.getMaxExperience()) + " years ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Salary Range ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s((_vm$jobCtc = _vm.jobCtc) === null || _vm$jobCtc === void 0 ? void 0 : _vm$jobCtc.getCurrency()) + " " + _vm._s((_vm$jobCtc2 = _vm.jobCtc) === null || _vm$jobCtc2 === void 0 ? void 0 : _vm$jobCtc2.getMinCtc()) + " to " + _vm._s((_vm$jobCtc3 = _vm.jobCtc) === null || _vm$jobCtc3 === void 0 ? void 0 : _vm$jobCtc3.getMaxCtc()) + " " + _vm._s((_vm$paymentFrequencie = _vm.paymentFrequencies[(_vm$jobCtc4 = _vm.jobCtc) === null || _vm$jobCtc4 === void 0 ? void 0 : (_vm$jobCtc4$getPaymen = _vm$jobCtc4.getPaymentFrequency) === null || _vm$jobCtc4$getPaymen === void 0 ? void 0 : _vm$jobCtc4$getPaymen.call(_vm$jobCtc4)]) === null || _vm$paymentFrequencie === void 0 ? void 0 : _vm$paymentFrequencie.name) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Equity ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(parseFloat((_vm$jobCtc5 = _vm.jobCtc) === null || _vm$jobCtc5 === void 0 ? void 0 : _vm$jobCtc5.getMinEquity())) + "% to " + _vm._s(parseFloat((_vm$jobCtc6 = _vm.jobCtc) === null || _vm$jobCtc6 === void 0 ? void 0 : _vm$jobCtc6.getMaxEquity())) + "% ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Description ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('html-view', {
    attrs: {
      "html": (_vm$jobInfo2 = _vm.jobInfo) === null || _vm$jobInfo2 === void 0 ? void 0 : _vm$jobInfo2.getAbout()
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Skills ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l((_vm$jobInfo3 = _vm.jobInfo) === null || _vm$jobInfo3 === void 0 ? void 0 : _vm$jobInfo3.getSkillsList(), function (sel, idx) {
    return _c('v-chip', {
      key: idx,
      attrs: {
        "color": "success"
      }
    }, [_vm._v(" " + _vm._s(sel.getSkill()) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Preference ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s((_vm$preferenceList = _vm.preferenceList) === null || _vm$preferenceList === void 0 ? void 0 : _vm$preferenceList.join(', ')) + " ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Benefits ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('ul', _vm._l(_vm.allBenefits, function (benefit) {
    return _c('li', {
      key: benefit
    }, [_vm._v(" " + _vm._s(benefit) + " ")]);
  }), 0)])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel', {
    staticClass: "mt-5"
  }, [_c('v-expansion-panel-header', {
    staticClass: "text-h6 font-weight-bold"
  }, [_vm._v(" Candidates ")]), _c('v-expansion-panel-content', {
    staticClass: "ma-0 pa-0"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "ma-0 pa-0"
  }, [_c('candidates', {
    attrs: {
      "displayCandidates": _vm.jobCandidatesTable,
      "candidateTableLoading": _vm.candidateTableLoading,
      "tableType": "JOB"
    }
  })], 1)], 1)], 1)], 1), _c('v-expansion-panel', {
    staticClass: "mt-5"
  }, [_c('v-expansion-panel-header', {
    staticClass: "text-h6 font-weight-bold d-flex justify-space-between"
  }, [_c('div', [_vm._v("Assessment")]), _c('div', {
    staticClass: "d-flex flex-row justify-end"
  }, [!_vm.isTestLinked ? _c('v-btn', {
    staticClass: "text-none mx-2",
    attrs: {
      "to": `/assessments/create/${(_vm$jobView3 = _vm.jobView) === null || _vm$jobView3 === void 0 ? void 0 : _vm$jobView3.getId()}`,
      "color": "primary",
      "elevation": "0"
    }
  }, [_vm._v(" Create Assessment ")]) : _vm._e()], 1)]), _c('v-expansion-panel-content', [_vm.isTestLinked ? _c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_c('router-link', {
    attrs: {
      "to": `/assessments/${_vm.testUrl}`
    }
  }, [_vm._v(" " + _vm._s((_this$linkedTest = this.linkedTest) === null || _this$linkedTest === void 0 ? void 0 : _this$linkedTest.getTitle()) + " ")])], 1)], 1) : _c('v-row', [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Link to one of the following assessment ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "column": "",
      "readonly": _vm.isTestLinked
    },
    model: {
      value: _vm.selectedTestForJob,
      callback: function ($$v) {
        _vm.selectedTestForJob = $$v;
      },
      expression: "selectedTestForJob"
    }
  }, _vm._l(_vm.displayTests, function (test) {
    return _c('v-radio', {
      key: test.id,
      attrs: {
        "value": test.id,
        "label": test === null || test === void 0 ? void 0 : test.name
      }
    });
  }), 1)], 1), _c('v-col', {
    staticClass: "d-flex flex-column-reverse",
    attrs: {
      "cols": "2"
    }
  }, [!_vm.isTestLinked ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "loading": _vm.linking
    },
    on: {
      "click": _vm.saveJobTestLink
    }
  }, [_vm._v(" Save ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }